<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
      <div class="row mt-5 align-items-center">
        <div class="col-lg-6 col-md-7">
          <div class="title-heading">
            <h1 class="heading mb-3">Manage all of you stuff using <span class="text-primary">Landrick.</span> app</h1>
            <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
              conversion centered bootstrap v5 html page.</p>
            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
                Store</a>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="text-md-end text-center ms-lg-4">
            <img src="assets/images/app/home.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-light">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Features Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">App Features</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">Use On Any Device</h4>
                  <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                    language corresponds.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">Feather Icons</h4>
                  <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                    language corresponds.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">Retina Ready</h4>
                  <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                    language corresponds.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">W3c Valid Code</h4>
                  <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                    language corresponds.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">Fully Responsive</h4>
                  <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                    language corresponds.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">Browser Compatibility</h4>
                  <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                    language corresponds.</p>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end col-->
  
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/feature.png" class="img-fluid" alt="">
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>

<div class="container mt-100 mt-60">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <div class="section-title mb-5 pb-2">
                <h4 class="title mb-4">Transection Between Buyers and Seller</h4>
                <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                        class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
                    awareness, drive traffic, connect.</p>
            </div>
            <img src="assets/images/payments/payment/seller-order.png" alt=""
                class="position-relative img-fluid mx-auto d-block" style="z-index: 1;">
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</div>
<!--end container-->
<!-- Transection End -->
<!-- </section> -->
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Section End -->

<!-- section Start -->
<section class="section bg-light">
    <!-- Client Review Start -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">What customers say about us</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate
                        awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 pt-2 mt-2 text-center">
                <!-- <div id="customer-testi" class="owl-carousel owl-theme"> -->
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <div class="card-body">
                                <p class="text-muted h6 fst-italic">" It seems that only fragments of the oem Ipsum',
                                    which is said
                                    to have originated in the 16th century. "</p>
                                <img src="assets/images/client/01.jpg"
                                    class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
                            </div>
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <div class="card-body">
                                <p class="text-muted h6 fst-italic">" The advantage of its Latin origin andor distract
                                    the viewer's
                                    attention from the layout. "</p>
                                <img src="assets/images/client/02.jpg"
                                    class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
                            </div>
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <div class="card-body">
                                <p class="text-muted h6 fst-italic">" There is now an abundance of readableives to the
                                    classic and
                                    tell short, funny or nonsensical stories. "</p>
                                <img src="assets/images/client/03.jpg"
                                    class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
                            </div>
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <div class="card-body">
                                <p class="text-muted h6 fst-italic">" According to most sources, Lorum Ipsuthe origin of
                                    the text by
                                    of the unusual word he could find "</p>
                                <img src="assets/images/client/04.jpg"
                                    class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
                            </div>
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <div class="card-body">
                                <p class="text-muted h6 fst-italic">" It seems that only fragments of the oem Ipsum',
                                    which is said
                                    to have originated in the 16th century. "</p>
                                <img src="assets/images/client/05.jpg"
                                    class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
                            </div>
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <div class="card-body">
                                <p class="text-muted h6 fst-italic">" It seems that only fragments of the otime certain
                                    letters at
                                    various positions within the text. "</p>
                                <img src="assets/images/client/06.jpg"
                                    class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                                <ul class="list-unstyled mb-0 mt-3">
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                    <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                </ul>
                                <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
                            </div>
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
                <!-- </div> -->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
    <!-- Client Review End -->
   
</section>
<!--end section-->
<!-- section End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Connect your business with more customers today</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness,
                        drive traffic, connect.</p>
                </div>

                <div class="contact-detail mt-5">
                    <div class="content mt-3 overflow-hidden d-block">
                        <h5 class="text-muted">Want to talk now ? <a href="tel:+152534-468-854"
                                class="text-primary h5">+152
                                534-468-854</a></h5>
                    </div>
                    <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary mt-3">Get Started
                        <span class="badge rounded-pill bg-danger ms-2">v4.2</span></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!--end section-->
<!-- Testi n Download cta End -->

<!-- Shape Start -->

<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>