import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.css'
})
export class ClientsComponent {
// Set Topbar Option
Menuoption = 'center';
Settingicon = true;

customOptions: OwlOptions = {
  // loop: true,
  mouseDrag: true,
  touchDrag: false,
  pullDrag: false,
  // autoplay: true,
  navSpeed: 700,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 2
    },
    900: {
      items: 3
    }
  },
  nav: false
};
}
