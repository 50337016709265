<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/company/aboutus.jpg');" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row mt-5 justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="pages-heading title-heading">
            <h2 class="text-white title-dark"> Aboutus </h2>
            <p class="text-white-50 para-desc mb-0 mx-auto">Start working with Landrick that can provide everything you
              need to generate awareness, drive traffic, connect.</p>
          </div>
        </div><!--end col-->
      </div><!--end row-->
  
      <div class="position-breadcrumb">
        <nav aria-label="breadcrumb" class="d-inline-block">
          <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
            <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">About</a></li>
            <li class="breadcrumb-item active" aria-current="page">Aboutus</li>
          </ul>
        </nav>
      </div>
    </div> <!--end container-->
  </section><!--end section-->
  
  <div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
        <div class="col-md-6">
          <img src="assets/images/company/about2.png" class="img-fluid" alt="">
        </div>
        <!--end col-->
  
        <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            <div class="d-flex mb-4">
              <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [countUp]="15" ></span>+</span>
              <span class="h6 align-self-end ms-2">Years <br> Experience</span>
            </div>
            <div class="section-title">
              <h4 class="title mb-4">Who we are ?</h4>
              <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
                everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the
                publishing industry or by web designers to occupy the space which will later be filled with 'real'
                content. This is required when, for example, the final text is not yet available. Dummy texts have been in
                use by typesetters since the 16th century.</p>
              <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-5">
      <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center pt-4"></app-clients-logo>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center mb-4 pb-2">
            <h6 class="text-primary">Work Process</h6>
            <h4 class="title mb-4">How do we works ?</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row">
        <div class="col-md-4 mt-4 pt-2">
          <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
            </div>
  
            <div class="card-body">
              <h5 class="text-dark">Discussion</h5>
              <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                originated</p>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
          <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
            </div>
  
            <div class="card-body">
              <h5 class="text-dark">Strategy & Testing</h5>
              <p class="text-muted mb-0">Generators convallis odio, vel pharetra quam malesuada vel. Nam porttitor
                malesuada.</p>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
          <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
            </div>
  
            <div class="card-body">
              <h5 class="text-dark">Reporting</h5>
              <p class="text-muted mb-0">Internet Proin tempus odio, vel pharetra quam malesuada vel. Nam porttitor
                malesuada.</p>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60">
      <div class="row align-items-end mb-4 pb-4">
        <div class="col-md-8">
          <div class="section-title text-md-left">
            <h6 class="text-primary">Services</h6>
            <h4 class="title mb-4">What we do ?</h4>
            <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Landrick</span>
              that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-center text-md-end">
            <a href="javascript:void(0)" class="text-primary h6">See More
              <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="d-flex">
        <ul ngbNav #nav="ngbNav" orientation="vertical" class="nav-pills rounded shadow p-3 mb-0 mt-4 col-md-4
            pt-2">
          <li ngbNavItem="top">
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Web Developing</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="show active p-4 rounded shadow" id="developing" role="tabpanel"
                aria-labelledby="webdeveloping">
                <img src="assets/images/work/7.jpg" class="img-fluid rounded shadow" alt="">
                <div class="mt-4">
                  <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy
                    text
                    is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.
                  </p>
                  <a href="javascript:void(0)" class="text-primary">See More
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Database Analysis</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
                <img src="assets/images/work/8.jpg" class="img-fluid rounded shadow" alt="">
                <div class="mt-4">
                  <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy
                    text
                    is also known as 'fill text'. It is said that song composers of the past used dummy texts as
                    lyrics.</p>
                  <a href="javascript:void(0)" class="text-primary">See More
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </div>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Server Security</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="p-4 rounded shadow" id="security" role="tabpanel" aria-labelledby="server">
                <img src="assets/images/work/9.jpg" class="img-fluid rounded shadow" alt="">
                <div class="mt-4">
                  <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy
                    text
                    is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.
                  </p>
                  <a href="javascript:void(0)" class="text-primary">See More
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </div>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Web Designing</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="p-4 rounded shadow" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                <img src="assets/images/work/12.jpg" class="img-fluid rounded shadow" alt="">
                <div class="mt-4">
                  <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy
                    text
                    is also known as 'fill text'. It is said that song composers of the past used dummy texts as
                    lyrics.</p>
                  <a href="javascript:void(0)" class="text-primary">See More
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </div>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-4 ms-4"></div>
      </div>
      <!--end col-->
  
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  
  <!-- Start -->
  <section class="section bg-light pt-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="video-solution-cta position-relative" style="z-index: 1;">
            <div class="position-relative">
              <img src="assets/images/cta-bg.jpg" class="img-fluid rounded-md shadow-lg" alt="">
              <div class="play-icon">
                <ng-template #content let-modal>
                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                    </youtube-player>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="content mt-md-4 pt-md-2">
              <div class="row justify-content-center">
                <div class="col-lg-10 text-center">
                  <div class="row align-items-center">
                    <div class="col-md-6 mt-4 pt-2">
                      <div class="section-title text-md-start">
                        <h6 class="text-white-50">Team</h6>
                        <h4 class="title text-white title-dark mb-0">Meet Experience <br> Team Member</h4>
                      </div>
                    </div>
  
                    <div class="col-md-6 col-12 mt-4 pt-md-2">
                      <div class="section-title text-md-start">
                        <p class="text-white-50 para-desc">Start working with Landrick that can provide everything you
                          need to generate awareness, drive traffic, connect.</p>
                        <a href="javascript:void(0)" class="text-white title-dark">Read More
                          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row -->
      <div class="feature-posts-placeholder bg-primary"></div>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- End -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->