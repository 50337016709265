@if(!hideFooter){
<div>
    <div>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-py-60">
                            <div class="row">
                                <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <a href="javascript:void(0)" class="logo-footer">
                                        <img src="assets/images/logo-light.png" height="60" alt="">
                                    </a>
                                    <p class="mt-4">Start working with Landrick that can provide everything you need to
                                        generate
                                        awareness, drive traffic, connect.</p>
                                    <!--end icon-->
                                </div>
                                <!--end col-->

                                <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Company</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li><a class="text-foot" routerLink="/aboutus"><i class="uil uil-angle-right-b me-1"></i>
                                                About us</a></li>
                                        <li><a class="text-foot" routerLink="/portfolio"><i class="uil uil-angle-right-b me-1"></i>
                                                Portfolio</a></li>
                                        <li><a class="text-foot" routerLink="/connect"><i class="uil uil-angle-right-b me-1"></i>
                                                Connect</a></li>
                                        <li><a class="text-foot" routerLink="/blog"><i class="uil uil-angle-right-b me-1"></i>
                                                Blog</a></li>
                                    </ul>
                                </div>
                                <!--end col-->

                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Usefull Links</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li><a class="text-foot" routerLink="/terms"><i class="uil uil-angle-right-b me-1"></i>
                                                Terms & Condition</a></li>
                                        <li><a class="text-foot" routerLink="/privacy-policy"><i class="uil uil-angle-right-b me-1"></i>
                                                Privacy Policy</a></li>
                                    </ul>
                                </div>
                                <!--end col-->

                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Newsletter</h5>
                                    <p class="mt-4">Sign up and receive the latest tips via email.</p>
                                    <form [formGroup]="newsForm" (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="foot-subscribe foot-white mb-3">
                                                    <label class="form-label">Write your email <span
                                                            class="text-danger">*</span></label>
                                                    <div class="form-icon position-relative">
                                                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                        <input type="email" name="email" id="emailsubscribe"
                                                            class="form-control ps-5 rounded"
                                                            placeholder="Your email : " required formControlName="email"
                                                            [ngClass]="{ 'is-invalid': submitted && form['email'].errors }">
                                                        @if(submitted && form['email'].errors){
                                                        <div class="invalid-feedback">
                                                            @if(form['email'].errors){
                                                            <div>Please Enter Email.</div>
                                                            }
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                            <div class="col-lg-12">
                                                <div class="d-grid">
                                                    <input type="submit" id="submitsubscribe" name="send"
                                                        class="btn btn-soft-primary" value="Subscribe">
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end row-->
                                    </form>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end container-->
            <!--end footer-->
            <div class="footer-py-30 footer-bar">
                <div class="container text-center">
                    <div class="row align-items-center">
                        <div class="col-sm-8">
                            <div class="text-sm-start">
                                <p class="mb-0">©
                                    <script type="text/javascript" id="www-widgetapi-script"
                                        src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                                        async=""></script>
                                    <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> {{year}}
                                    <b>SHUBHAVI INFOTECH</b>. All rights reserved. 
                                </p>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-sm-4 ml-5">
                            <ul class="list-unstyled text-sm-end social-icon foot-social-icon mb-0">
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                            </ul>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </div>
            <!--end footer-->
        </footer>
    </div>
</div>
}