<!-- TAGLINE START-->
@if(sliderTopbar == true){
<div class="tagline bg-light">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0"><b>EOG</b> $ 55.88 <span class="text-success">+$ 4.62 ( +9.01%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>CHKP</b> $ 120.03 <span class="text-danger">-$ 14.07 ( -10.49%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>FANG</b> $ 63.58 <span class="text-success">+$ 5.17 ( +8.84%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>M</b> $ 14.75 <span class="text-success">+$ 1.05 ( +7.66% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>PKI</b> $ 139.72 <span class="text-danger">-$ 11.41 ( -7.55%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ABMD</b> $ 326.23 <span class="text-danger">-$ 21.61 ( -6.21%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN</b> $ 37,471.47 <span class="text-danger">+$ 492.60 (
                +1.33% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>XRP</b> <span> $ 0.39</span><span class="text-muted"> UNCH</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>LITECOIN</b> <span> $ 148.67</span><span class="text-danger">-$
                5.58 ( -3.62% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN CASH</b> <span> $ 427.37</span><span
                class="text-danger">-$ 15.98 ( -3.60% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ETHEREUM</b> $ 1,647.87 <span class="text-danger">+$ 14.51 (
                +0.89% )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
}
<!-- TAGLINE END-->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      @if(navClass !== 'nav-light'){
      <a class="logo" routerLink="/index">
        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="50" alt="">
        <img src="assets/images/logo-light.png" class="logo-dark-mode" height="50" alt="">
      </a>
      }
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      @if(buttonList == true){
      <ul class="buy-button list-inline mb-0">
        <li class="list-inline-item mb-0 ms-1">
          <div class="dropdown">
            <button type="button" class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="uil uil-search text-muted"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px;">
              <form>
                <input type="text" id="text" name="name" class="form-control border bg-white" placeholder="Search...">
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-facebook-f icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-github icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-twitter icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-linkedin-alt icons"></i></a>
        </li>
      </ul>
      }


      @if(isdeveloper == true){
      <ul class="buy-button list-inline mb-0">
        <li class="list-inline-item mb-0">
          <div class="dropdown">
            <button type="button" class="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="uil uil-search text-dark fs-5 align-middle pe-2"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px;">
              <form>
                <input type="text" id="text2" name="name" class="form-control border bg-white" placeholder="Search...">
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 pe-2">
          <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight" (click)="openright(content)">
            <div class="btn btn-icon btn-soft-primary" id="settingbtn"><i-feather name="settings"
                class="fea icon-sm"></i-feather></div>
          </a>
        </li>
        <li class="list-inline-item mb-0">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-github icons"></i></a>
        </li>
        <ng-template #content let-modal>
          <div class="modal-lg modal-dialog-centered" role="document">
            <div class="modal-content rounded shadow-lg border-0 overflow-hidden position-relative">
              <button type="button" class="btn-close position-absolute top-0 end-0 mt-2 me-2"
                (click)="modal.dismiss('Cross click')" style="z-index: 1" data-bs-dismiss="modal"
                aria-label="Close"></button>
              <div class="modal-body p-0">
                <div class="container-fluid px-0">
                  <div class="row align-items-center g-0">
                    <div class="col-lg-6 col-md-5">
                      <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="">
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-md-7">
                      <form class="login-form p-4">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label class="form-label">Your Email <span class="text-danger">*</span></label>
                              <div class="form-icon position-relative">
                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                <input type="email" class="form-control ps-5" placeholder="Email" name="email"
                                  required="">
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label class="form-label">Password <span class="text-danger">*</span></label>
                              <div class="form-icon position-relative">
                                <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                <input type="password" class="form-control ps-5" placeholder="Password" required="">
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12">
                            <div class="d-flex justify-content-between">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="customCheck1">
                                  <label class="custom-control-label ms-1" for="customCheck1">Remember me</label>
                                </div>
                              </div>
                              <p class="forgot-pass mb-0"><a routerLink="/auth-re-password"
                                  class="text-dark fw-bold">Forgot password ?</a></p>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12 mb-0">
                            <div class="d-grid">
                              <button class="btn btn-primary">Sign in</button>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-12 text-center">
                            <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a
                                routerLink="/auth-signup" class="text-dark fw-bold">Sign Up</a></p>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </form>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <!--end container-->
              </div>
            </div>
          </div>
        </ng-template>
      </ul>
      }



      @if(navClass === 'nav-light'){
      <a class="logo" routerLink="/index">
        <img src="assets/images/logo-dark.png" class="l-dark" height="50" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="50" alt="">
      </a>
      }
    </div>
    <!--Login button Start-->

    @if(Settingicon == true && navClass === 'nav-light'){
    <ul class="buy-button list-inline mb-0">
      <li class="list-inline-item mb-0 login-btn-primary">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" (click)="openright(content)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn"><i-feather name="info"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>
    </ul>
    }


    @if(Settingicon == true && navClass !== 'nav-light'){
    <ul class="buy-button list-inline mb-0">
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" (click)="openright(content)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn"><i-feather name="info"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>
    </ul>
    }
    <!--Login button End-->

    @if(Menuoption == 'center'){
    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu nav-center" [class]="navClass">
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Development</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/web-development">Website Development</a> </li>
            <li><a class="nav-link-ref" routerLink="/web-application">Web Application</a></li>
            <li><a class="nav-link-ref" routerLink="/mobile-application">Mobile Application</a></li>
            <li><a class="nav-link-ref" routerLink="/ecommerce">Ecommerce</a></li>
            <li><a class="nav-link-ref" routerLink="/ui-ux">UI/UX</a></li>
          </ul>
        </li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Digital Marketing</a><span
            class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/social-media">Social Media</a></li>
            <li><a class="nav-link-ref" routerLink="/seo">SEO</a></li>
            <li><a class="nav-link-ref" routerLink="/google-ads-management">Google Ads Management</a></li>
            <li><a class="nav-link-ref" routerLink="/lead-generation">Lead Generation</a></li>
            <li><a class="nav-link-ref" routerLink="/graphic-design">Graphic Design</a></li>
          </ul>
        </li>
        <li><a class="nav-link-ref" routerLink="/portfolio">Portfolio</a></li>

        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">About</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/aboutus">About US</a></li>
            <li><a class="nav-link-ref" routerLink="/blog">Blog</a></li>
            <li><a class="nav-link-ref" routerLink="/clients">Clients</a></li>
          </ul>
        </li>
        <li><a class="nav-link-ref" routerLink="/connect">Connect</a></li>
      </ul>
      <!--end navigation menu-->
    </div>
    }
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header p-4 border-bottom">
    <h5 id="offcanvasRightLabel" class="mb-0">
      <img src="assets/images/logo-dark.png" height="50" class="light-version" alt="">
      <img src="assets/images/logo-light.png" height="50" class="dark-version" alt="">
    </h5>
    <button type="button" class="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas"
      aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="uil uil-times fs-4"></i></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-12">
        <img src="assets/images/contact.svg" class="img-fluid d-block mx-auto" style="max-width: 256px;" alt="">
        <div class="card border-0 mt-0" style="z-index: 1">
          <h5 class="card-title text-center">Get In Touch</h5>
          <div class="card-body p-0">
            <form [formGroup]="validationForm">
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              <div class="col-12">
                <div class="mb-3">
                  <div class="form-icon position-relative">
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input type="text" class="form-control ps-5" id="name" placeholder="Name" formControlName="name"
                      [ngClass]="{'is-invalid': f.name.touched && f.name.errors}" [(ngModel)]="connectModel.name">
                    <div *ngIf="f.name.touched && f.name.errors" class="invalid-feedback">
                      <span *ngIf="f.name.errors.required">This Field is
                        required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input name="email" id="email" type="email" class="form-control ps-5" formControlName="email"
                      placeholder="Email :" [(ngModel)]="connectModel.email"
                      [ngClass]="{'is-invalid': f.email.touched && f.email.errors}">
                    <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Email must be a valid email
                        address</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="mb-3">
                  <div class="form-icon position-relative">
                    <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                    <input type="number" class="form-control ps-5" id="contact" placeholder="Contact"
                      formControlName="contact" [ngClass]="{'is-invalid': f.contact.touched && f.contact.errors}"
                      [(ngModel)]="connectModel.contact">
                    <div *ngIf="f.contact.touched && f.contact.errors" class="invalid-feedback">
                      <span *ngIf="f.contact.errors.required">This Field is
                        required.</span>
                    </div>
                    <div *ngIf="f.contact.touched && f.contact.errors" class="invalid-feedback">
                      <div *ngIf="f.contact.errors?.['pattern']">
                        Contact Number not valid
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="mb-3">
                  <div class="form-icon position-relative">
                    <i-feather name="file-text" class="fea icon-sm icons"></i-feather>
                    <input type="text" class="form-control ps-5" id="subject" placeholder="Subject"
                      formControlName="subject" [ngClass]="{'is-invalid': f.subject.touched && f.subject.errors}"
                      [(ngModel)]="connectModel.subject">
                    <div *ngIf="f.subject.touched && f.subject.errors" class="invalid-feedback">
                      <span *ngIf="f.subject.errors.required">This Field is
                        required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <div class="form-icon position-relative">
                    <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                    <textarea name="comments" class="form-control ps-5" id="comments" rows="3" placeholder="comments"
                      formControlName="comments" [ngClass]="{'is-invalid': f.comments.touched && f.comments.errors}"
                      [(ngModel)]="connectModel.comments">
                        </textarea>
                    <div *ngIf="f.comments.touched && f.comments.errors" class="invalid-feedback">
                      <span *ngIf="f.comments.errors.required">This Field is
                        required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid">
                    <button type="submit" id="submit" name="send" class="btn btn-primary" (click)="saveConnectData()"
                      [disabled]="validationForm.invalid">Send
                      Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas-footer p-4 border-top text-center">
    <ul class="list-unstyled social-icon social mb-0 d-flex justify-content-center gap-1">
      <li class="list-inline-item mb-0"><a href="https://www.facebook.com/" target="_blank"
          class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.instagram.com/" target="_blank"
          class="rounded"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://twitter.com/" target="_blank" class="rounded"><i
            class="uil uil-twitter align-middle" title="twitter"></i></a></li>
      <li class="list-inline-item mb-0"><a href="mailto:hello@shubhaviinfotech.com" class="rounded"><i
            class="uil uil-envelope align-middle" title="email"></i></a></li>
    </ul><!--end icon-->
  </div>
</ng-template>
<!-- Offcanvas End -->