
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    bookingTimeInterval: any = [];
    constructor(
        private httpClient: HttpClient,
        // private firestore: AngularFirestore
    ) { }
    // saveContactListData(data: any) {
    //     return this.httpClient.post<any>(ApiService.saveCustomerEnquiryURL, data);
    // }
}
