import { Component } from '@angular/core';

@Component({
  selector: 'app-google-ads-management',
  templateUrl: './google-ads-management.component.html',
  styleUrl: './google-ads-management.component.css'
})
export class GoogleAdsManagementComponent {
// Set Topbar Option
Menuoption = 'center';
Settingicon = true;
}
