<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-7">
          <div class="title-heading mt-4">
            <h1 class="heading mb-3">Build Anything <br>For Your Project</h1>
            <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
              conversion centered bootstrap v5 html page.</p>
            <div class="mt-4 pt-2">
              <a routerLink="/page-services" class="btn btn-primary m-1">Our Services</a>
              <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
                <i-feather name="video" class="icons"></i-feather>
              </a><span class="fw-bold text-uppercase small align-middle ms-2">Watch Now</span>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                  </youtube-player>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <img src="assets/images/illustrator/services.svg" alt="">
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Feature Start -->
  <section class="section pt-0">
    <div class="container">
      <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Feature End -->
  
  <!-- counter Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title me-lg-5">
            <h4 class="title mb-4">Get Notified About Importent Email</h4>
            <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression and
              facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is
              relatively realistic.</p>
            <a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
                class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-6 order-1 order-md-2">
          <img src="assets/images/saas/classic01.png" class="img-fluid" alt="">
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center" id="counter">
        <div class="col-md-4 mt-4 pt-2">
          <div class="counter-box text-center px-lg-4">
            <h2 class="mb-0 text-primary display-4"><span class="counter-value" [countUp]="97" [options]="option"></span>%</h2>
            <h5 class="counter-head">Happy Client</h5>
            <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century.</p>
          </div>
          <!--end counter box-->
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-4 pt-2">
          <div class="counter-box text-center px-lg-4">
            <h2 class="mb-0 text-primary display-4"><span class="counter-value" [countUp]="15" [options]="option"></span>+</h2>
            <h5 class="counter-head">Awards</h5>
            <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century.</p>
          </div>
          <!--end counter box-->
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-4 pt-2">
          <div class="counter-box text-center px-lg-4">
            <h2 class="mb-0 text-primary display-4"><span class="counter-value" [countUp]="98" [options]="option"></span>%</h2>
            <h5 class="counter-head">Project Complete</h5>
            <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century.</p>
          </div>
          <!--end counter box-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- counter End -->
  
  <!-- Testimonial Start -->
  <section class="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img src="assets/images/illustrator/analyze_report_4.svg" class="me-md-4" alt="">
        </div>
        <!--end col-->
  
        <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">Clean And Modern Code</h4>
            <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression and
              facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is
              relatively realistic.</p>
            <a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
                class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Our Happy Customers</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-customer-testmonial [customerData]="customerData"></app-customer-testmonial>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Subscribe for our Latest Newsletter</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row justify-content-center mt-4 pt-2">
        <div class="col-lg-7 col-md-10">
          <div class="subcribe-form">
            <form class="ms-0">
              <input type="email" id="email" name="email" class="bg-white shadow rounded-pill" placeholder="E-mail :">
              <button type="submit" class="btn btn-pills btn-primary">Submit <i class="uil uil-arrow-right"></i></button>
            </form>
            <!--end form-->
          </div>
          <!--end subscribe form-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Testimonial End -->
  
  <div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->