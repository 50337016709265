<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Contact US </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact US</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Start Contact -->
<section class="section mb-5">
    <div class="container">
        <div class="row">
            <div class="card shadow rounded border-0">
                <div class="card-body py-5">
                    <h4 class="card-title">Get In Touch !</h4>
                    <div class="custom-form mt-3">
                        <form [formGroup]="validationForm">
                            <p id="error-msg" class="mb-0"></p>
                            <div id="simple-msg"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" id="name" placeholder="Name"
                                                formControlName="name"
                                                [ngClass]="{'is-invalid': f.name.touched && f.name.errors}"
                                                [(ngModel)]="connectModel.name">
                                            <div *ngIf="f.name.touched && f.name.errors" class="invalid-feedback">
                                                <span *ngIf="f.name.errors.required">This Field is
                                                    required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input name="email" id="email" type="email" class="form-control ps-5"
                                                formControlName="email" placeholder="Email :"
                                                [(ngModel)]="connectModel.email"
                                                [ngClass]="{'is-invalid': f.email.touched && f.email.errors}">
                                            <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email
                                                    address</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Contact</label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input type="number" class="form-control ps-5" id="contact"
                                                placeholder="Contact" formControlName="contact"
                                                [ngClass]="{'is-invalid': f.contact.touched && f.contact.errors}"
                                                [(ngModel)]="connectModel.contact">
                                            <div *ngIf="f.contact.touched && f.contact.errors" class="invalid-feedback">
                                                <span *ngIf="f.contact.errors.required">This Field is
                                                    required.</span>
                                            </div>
                                            <div *ngIf="f.contact.touched && f.contact.errors" class="invalid-feedback">
                                                <div *ngIf="f.contact.errors?.['pattern']">
                                                    Contact Number not valid
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Subject</label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="file-text" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" id="subject"
                                                placeholder="Subject" formControlName="subject"
                                                [ngClass]="{'is-invalid': f.subject.touched && f.subject.errors}"
                                                [(ngModel)]="connectModel.subject">
                                            <div *ngIf="f.subject.touched && f.subject.errors" class="invalid-feedback">
                                                <span *ngIf="f.subject.errors.required">This Field is
                                                    required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label class="form-label">Comments <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="message-circle"
                                                class="fea icon-sm icons clearfix"></i-feather>
                                            <textarea name="comments" class="form-control ps-5" id="comments" rows="4"
                                                placeholder="comments" formControlName="comments"
                                                [ngClass]="{'is-invalid': f.comments.touched && f.comments.errors}"
                                                [(ngModel)]="connectModel.comments">
                                                </textarea>
                                            <div *ngIf="f.comments.touched && f.comments.errors"
                                                class="invalid-feedback">
                                                <span *ngIf="f.comments.errors.required">This Field is
                                                    required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <div class="d-grid ">
                                        <button type="submit" id="submit" name="send" class="btn btn-primary"
                                            (click)="saveConnectData()" [disabled]="validationForm.invalid">Send
                                            Message</button>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end custom-form-->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-md-4">
                <div class="card border-0 text-center features feature-primary feature-clean">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-4">
                        <h5 class="fw-bold">Phone</h5>
                        <p class="text-muted">Start working with Landrick that can provide everything</p>
                        <a href="tel:+152534-468-854" class="text-primary">+91 81419 52604</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-primary feature-clean">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-4">
                        <h5 class="fw-bold">Email</h5>
                        <p class="text-muted">Start working with Landrick that can provide everything</p>
                        <a href="mailto:hello@shubhaviinfotech.com"
                            class="text-primary">hello&#64;shubhaviinfotech.com</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-primary feature-clean">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-4">
                        <h5 class="fw-bold">Location</h5>
                        <p class="text-muted">C/54 Northwest Freeway, Suite 558, <br>Houston, USA 485</p>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                    height="450" width="750">
                                </iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>