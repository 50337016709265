import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountUpModule } from 'ngx-countup';
import { NgxMasonryModule } from 'ngx-masonry';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from "./shared/shared.module";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { IndexComponent } from './core/components/index/index.component';
import { FeatherModule } from 'angular-feather';
// Apex chart
import { NgApexchartsModule } from 'ng-apexcharts';
import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConnectComponent } from './core/components/connect/connect.component';
import { AboutusComponent } from './core/components/aboutus/aboutus.component';
import { BlogComponent } from './core/components/blog/blog.component';
import { ClientsComponent } from './core/components/clients/clients.component';
import { SeoComponent } from './core/components/seo/seo.component';
import { SocialMediaComponent } from './core/components/social-media/social-media.component';
import { WebDevelopmentComponent } from './core/components/web-development/web-development.component';
import { WebApplicationComponent } from './core/components/web-application/web-application.component';
import { MobileApplicationComponent } from './core/components/mobile-application/mobile-application.component';
import { UiUxComponent } from './core/components/ui-ux/ui-ux.component';
import { EcommerceComponent } from './core/components/ecommerce/ecommerce.component';
import { GraphicDesignComponent } from './core/components/graphic-design/graphic-design.component';
import { PortfolioComponent } from './core/components/portfolio/portfolio.component';
import { LeadGenerationComponent } from './core/components/lead-generation/lead-generation.component';
import { GoogleAdsManagementComponent } from './core/components/google-ads-management/google-ads-management.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { TermsOfConditionComponent } from './core/components/terms-of-condition/terms-of-condition.component';
import { ErrorComponent } from './core/components/error/error.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ConnectComponent,
    MasterPageComponent,
    SwitcherComponent,
    IndexComponent,
    WebApplicationComponent,
    AboutusComponent,
    BlogComponent,
    ClientsComponent,
    SeoComponent,
    SocialMediaComponent,
    WebDevelopmentComponent,
    MobileApplicationComponent,
    UiUxComponent,
    EcommerceComponent,
    GraphicDesignComponent,
    PortfolioComponent,
    LeadGenerationComponent,
    GoogleAdsManagementComponent,
    PrivacyPolicyComponent,
    TermsOfConditionComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], {}),
    YouTubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    NgApexchartsModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountUpModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule,
    SimplebarAngularModule,
    HttpClientModule,
    ToastrModule.forRoot()
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
