<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/corporate/pages.jpg') top;" id="home">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading title-heading">
          <h2 class="text-white title-dark"> Our Clients </h2>
          <p class="text-white-50 para-desc mb-0 mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index"> Home</a></li>
          <li class="breadcrumb-item"><a routerLink="/index-corporate">About</a></li>
          <li class="breadcrumb-item active" aria-current="page">Clients</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section mb-5">
  <div class="container">
    <div class="row align-items-center">
      <h4>Our Clients</h4>
      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <p class="b-2">
          We are trusted by over 500+ clients. Join them by using our services and grow your business.
        </p>
      </div><!--end col-->
      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 ">
        <p>
          We have clients who believe in the power of imagination and are willing to take a calculated risk in order to
          achieve extraordinary outcomes.
        </p>
      </div><!--end col-->
    </div><!--end row-->
  </div><!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Clients</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/01.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
            <small class="designation text-muted">C.E.O</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/04.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
            <small class="designation text-muted">Director</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/02.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
            <small class="designation text-muted">Manager</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/03.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
            <small class="designation text-muted">Developer</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/06.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Calvin Carlo</a></h5>
            <small class="designation text-muted">C.E.O</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/05.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Juhi Chawla</a></h5>
            <small class="designation text-muted">Director</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/07.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Arlo Walker</a></h5>
            <small class="designation text-muted">Manager</small>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team team-primary text-center border-0">
          <div class="position-relative">
            <img src="assets/images/client/08.jpg" class="img-fluid rounded shadow" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                    class="icons"></i-feather></a></li>
              <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                  class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                    class="icons"></i-feather></a></li>
            </ul><!--end icon-->
          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Randeep Huda</a></h5>
            <small class="designation text-muted">Developer</small>
          </div>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div><!--end container-->

</section><!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>