<!-- Start Hero -->
<section class="bg-half-170 pb-0 bg-light d-table w-100 overflow-hidden"
  style="background: url('assets/images/shapes/shape2.png') top; z-index: 0;" id="home">
  <div class="container">
    <div class="row align-items-center mt-5 mt-sm-0">
      <div class="col-md-6">
        <div class="title-heading text-center text-md-start">
          <span class="badge rounded-pill bg-soft-primary">Freelancing</span>
          <h4 class="heading mb-3 mt-2">Marketing is more than just advertising </h4>
          <p class="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto">Shubhavi Infotech provides you with comprehensive marketing plans and knowledge in order to build a relationship of trust, loyalty and respect. It is about establishing a strong and thriving connection with customers.
          </p>

          <div class="mt-4">
            <a href="javascript:void(0)" routerLink="/connect" class="btn btn-primary">Connect us</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="freelance-hero position-relative">
          <div class="bg-shape position-relative">
            <img src="assets/images/freelancer/freelancer.png" class="mx-auto d-block img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->
<!-- Start -->
<section class="section overflow-hidden">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card border-0 text-center features feature-primary feature-clean rounded">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-flip-v d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Development</a>
            <p class="text-muted mt-3 mb-0">Make a lasting impression with a professionally designed and user-friendly website. We create responsive, SEO-optimized websites that captivate your audience, drive engagement, and deliver seamless user experiences across all devices.
            </p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-4 pt-sm-0">
        <div class="card border-0 text-center features feature-primary feature-clean rounded">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Graphic Designing</a>
            <p class="text-muted mt-3 mb-0">Our talented graphic designers specialize in creating visually stunning designs that captivate your audience and leave a lasting impression. Whether you need a new logo, branding materials, or eye-catching social media graphics, we'll work closely with you to bring your ideas to fruition.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-4 pt-lg-0">
        <div class="card border-0 text-center features feature-primary feature-clean rounded">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-object-group d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Marketing</a>
            <p class="text-muted mt-3 mb-0">Engage with your audience and build brand loyalty through strategic social media marketing. We develop compelling content, run targeted ads, and manage your social media channels to increase brand awareness, drive website traffic, and generate leads.
            </p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-6">
        <img src="assets/images/digital/about.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">We provide innovative digital experience</h4>
          <p class="text-muted">At Shubamvi Infotech, we're passionate about helping businesses ignite their online presence through innovative digital marketing strategies and captivating graphic designs. With years of experience in the industry, our team of experts is dedicated to bringing your vision to life and driving tangible results for your business.
          </p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Tailored Approach: We believe in understanding each client's unique goals and crafting customized strategies to achieve them.</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Integrated Solutions: Our integrated approach combines digital marketing tactics with captivating graphic designs to create cohesive brand experiences across all channels.
            </li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Continuous Optimization: In the ever-evolving digital landscape, we stay ahead of the curve by continuously monitoring performance metrics and refining our strategies. </li>
          </ul>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary m-1 me-2">Read More <i
                class="uil uil-angle-right-b"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">

      <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified flex-column rounded col-md-5 pr-0">
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="p-3 text-start">
              <h5>Management Tools</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by
                web designers to occupy the space which will later be filled with 'real' content.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="saas-feature-shape-right position-relative">
              <img src="assets/images/digital/1.png" class="img-fluid mx-auto d-block" alt="">
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="p-3 text-start">
              <h5>Increase Effectiveness</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by
                web designers to occupy the space which will later be filled with 'real' content.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/digital/2.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="p-3 text-start">
              <h5>Data Analysis</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by
                web designers to occupy the space which will later be filled with 'real' content.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/digital/3.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="col-md-7 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0"></div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <img src="assets/images/illustrator/app.svg" alt="">
      </div>
      <!--end col--->

      <div class="col-lg-6 col-md-6 col-12 order-1 order-md-2">
        <div class="section-title">
          <h4 class="title mb-4">A marketing agency that <br> helps you succeed</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <a href="javascript:void(0)" class="btn btn-primary">Find More
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
        </div>
      </div>
      <!--end col--->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<div class="container ">
  <div class="row justify-content-center">
      <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Client's Review</h4>
              <p class="text-muted para-desc mx-auto mb-0">Partner with Creative Minds Digital Marketing and Graphic Designing Company to unlock the full potential of your brand's online presence and visual identity. Let's embark on a journey of creativity, innovation, and success together.
              </p>
          </div>
      </div>
      <!--end col-->
  </div>
  <!--end row-->

  <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
</div>


<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- End -->

<section class="section border-bottom bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12 order-1 order-md-2">
        <img src="assets/images/illustrator/user_interface.svg" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Available for your <br> Smartphones</h4>
          <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <div class="my-4">
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 me-2"><i class="uil uil-apple"></i> App
              Store</a>
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>

          <div class="d-inline-block">
            <div class="pt-4 d-flex align-items-center border-top">
              <i-feather name="smartphone" class="fea icon-md me-2 text-primary"></i-feather>
              <div class="content">
                <h6 class="mb-0">Install app now on your cellphones</h6>
                <a href="javascript:void(0)" class="text-primary">Learn More <i
                    class="uil uil-angle-right-b align-middle"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="javascript:void(0)" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->