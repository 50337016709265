<!-- Hero Start -->
<section class="bg-home d-flex align-items-center background-effect bg-soft-primary" id="home">
    <div class="container z-index-1">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading">
                    <h4 class="display-4 fw-bold text-dark mb-4">Discover, <br> find and sell <br> monster NFTs</h4>
                    <p class="para-desc mx-auto text-muted mb-0">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-4">
                        <a href="" class="btn btn-primary"><i class="uil uil-bitcoin"></i> Mint NFT</a>
                        <a href="" class="btn btn-soft-primary ms-1"><i class="uil uil-file-bookmark-alt"></i>
                            Explore</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="assets/images/nft/home.png" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <ul class="circles p-0 mb-0">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</section><!--end section-->
<!-- Hero End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>