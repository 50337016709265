
<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
      <div class="row mt-5 justify-content-center">
          <div class="col-lg-12 text-center">
              <div class="pages-heading">
                  <h4 class="title mb-0"> Portfolio </h4>
              </div>
          </div> <!--end col-->
      </div><!--end row-->

      <div class="position-breadcrumb">
          <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Portfolio</li>
              </ul>
          </nav>
      </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
  </div>
</div>
<!--Shape End-->
<!-- About Start -->
<section class="section">
  <div class="container" id="portfolio">
    <div class="row">
      <div class="col-12">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">My Work & Portfolio</h4>
          <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      @for(item of workList;track $index){
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-primary work-modern position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <img src="{{item.image }}" class="img-fluid" alt="work-image">
            <div class="overlay-work"></div>
            <div class="content">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-white title">{{item.title}}</a></h5>
              <h6 class="text-white-50 tag mt-1 mb-0">{{item.category}}</h6>
            </div>
            <div class="icons text-center"> 
              <a (click)="open($index)"
                class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image">
                <i-feather name="camera" class="fea icon-sm"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
    }
      <!--end col-->

      <div class="col-12 mt-4 pt-2">
        <a routerLink="/portfolio-modern-three" class="btn btn-outline-primary">See More <i
            class="mdi mdi-chevron-right"></i></a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
      <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
      </svg>
  </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->