<!-- Hero Start -->
<section class="bg-half-260 bg-light d-table w-100" style="background: url('assets/images/seo/bg.png') center center;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="title-heading">
                    <span class="badge rounded-pill bg-soft-primary"><b>Best SEO Service</b></span>
                    <h4 class="heading mt-2 mb-4">An expert search engine optimization</h4>
                    <p class="para-desc mx-auto text-muted mb-0">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-pills btn-primary m-1">Get Started</a>
                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-primary m-1">Learn More</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-11">
                <div class="features-absolute">
                    <div id="grid" class="row">
                        <ngx-masonry>
                            @for(item of seoAgency;track $index){
                            <div ngxMasonryItem class="spacing picture-item">
                                <img src="{{item.images}}" class="img-fluid shadow seo-hero-widget rounded-md" alt="">
                            </div>
                        }
                            <!--end col-->
                        </ngx-masonry>
                    </div>
                    <!--end row-->
                </div>
                <!--end div-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <span class="badge rounded-pill bg-soft-primary">Work Process</span>
                    <h4 class="title mt-3 mb-4">How it works ?</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <app-services [servicesData]="servicesData" service_class="features feature-primary text-center mt-5"></app-services>
        <div class="col-12 mt-5 text-center">
            <a href="javascript:void(0)" class="btn btn-pills btn-primary">See More</a>
        </div>
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/illustrator/big-launch.svg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">We help you increase <br> sales by improving SEO</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                        component from the Application theme and use it in the Website.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced
                            Marketing Agency</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">See more solutions <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/illustrator/maintenance.svg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Advantages of SEO & <br> Marketing strategy</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                        component from the Application theme and use it in the Website.</p>
                    <p class="text-muted">Using Landrick to build your site means never worrying about designing another
                        page or cross browser compatibility. Our ever-growing library of components and pre-designed
                        layouts will make your life easier.</p>
                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-primary">Know more</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="bg-primary rounded shadow py-5" style="background: url('assets/images/seo/cta.png') center center;">
            <div class="container my-md-5">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="section-title">
                            <h4 class="title text-white title-dark mb-4">Check your website SEO</h4>
                            <p class="text-white-50 para-desc mb-0 mx-auto">Start working with Landrick that can provide
                                everything you need to generate awareness, drive traffic, connect.</p>
                            <div class="subcribe-form mt-4 pt-2">
                                <form>
                                    <div class="mb-0">
                                        <input type="url" id="url" class="border rounded-lg"
                                            style="opacity: 0.85;" required placeholder="https://themesbrand.com">
                                        <button type="submit" class="btn btn-pills btn-primary">Get Started</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div>
        <!--end div-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Client's Review</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->