import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrl: './connect.component.css'
})
export class ConnectComponent {
  name: string = '';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  connectModel: any = {};
  validationForm!: FormGroup;
  submitted = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required,]],
      subject: ['', [Validators.required,]],
      contact: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      comments: ['', [Validators.required,]],

    })
  }

  get f() { return this.validationForm.controls; }

  saveConnectData() {
    this.submitted = true;
    if (this.validationForm.invalid) {
      return;
    }
    else {
    }
  }

}
