import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-ui-ux',
  templateUrl: './ui-ux.component.html',
  styleUrl: './ui-ux.component.css'
})
export class UiUxComponent {
  // Set Topbar Option
Menuoption = 'center';
Settingicon = true;

  simple_pricingData = [
    {
      title: "Free",
      price: 0,
      list: ["Full Access", "Enhanced Security", "Source Files", "1 Domain Free"],
      btn: "Buy Now"
    },
    {
      warning: "Best",
      title: "Starter",
      price: 39,
      list: ["Full Access", "Source Files", "Free Appointments", "Enhanced Security"],
      btn: "Get Started"
    },
    {
      title: "Professional",
      price: 59,
      list: ["Full Access", "Enhanced Security", "Source Files", "1 Domain Free"],
      btn: "Try It Now"
    }
  ];

  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
}
