import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { ConnectComponent } from './core/components/connect/connect.component';
import { AboutusComponent } from './core/components/aboutus/aboutus.component';
import { BlogComponent } from './core/components/blog/blog.component';
import { ClientsComponent } from './core/components/clients/clients.component';
import { SeoComponent } from './core/components/seo/seo.component';
import { SocialMediaComponent } from './core/components/social-media/social-media.component';
import { WebDevelopmentComponent } from './core/components/web-development/web-development.component';
import { MobileApplicationComponent } from './core/components/mobile-application/mobile-application.component';
import { UiUxComponent } from './core/components/ui-ux/ui-ux.component';
import { EcommerceComponent } from './core/components/ecommerce/ecommerce.component';
import { GraphicDesignComponent } from './core/components/graphic-design/graphic-design.component';
import { PortfolioComponent } from './core/components/portfolio/portfolio.component';
import { WebApplicationComponent } from './core/components/web-application/web-application.component';
import { LeadGenerationComponent } from './core/components/lead-generation/lead-generation.component';
import { GoogleAdsManagementComponent } from './core/components/google-ads-management/google-ads-management.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { TermsOfConditionComponent } from './core/components/terms-of-condition/terms-of-condition.component';
import { ErrorComponent } from './core/components/error/error.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      
      { path: 'index', component: IndexComponent },
      { path: 'connect', component: ConnectComponent },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'seo', component: SeoComponent },
      { path: 'social-media', component: SocialMediaComponent },
      { path: 'web-development', component: WebDevelopmentComponent },
      { path: 'web-application', component: WebApplicationComponent },
      { path: 'mobile-application', component: MobileApplicationComponent },
      { path: 'ui-ux', component: UiUxComponent },
      { path: 'ecommerce', component: EcommerceComponent },
      { path: 'graphic-design', component: GraphicDesignComponent },
      { path: 'portfolio', component: PortfolioComponent },
      { path: 'lead-generation', component: LeadGenerationComponent },
      { path: 'google-ads-management', component: GoogleAdsManagementComponent },
      { path: 'terms', component: TermsOfConditionComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },




      { path: '#', component: SwitcherComponent },
    ]

  },
  { path: '**',pathMatch:'full' ,component: ErrorComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],// Enable scrolling to anchors
  anchorScrolling: "enabled"
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
